import { v4 as uuid } from 'uuid'
import { sentryService } from '../SentryService'

import { getNowUnixTimestamp } from '../../utils/dateUtils'
import { _analytics } from './AnalyticsImpl'
import { filterObjectBlankAttributes } from '../../utils/utils'
import StorageManager from '../StorageManager'
import { _EVENT_NAMES, _SCREENS } from './AnalyticsEvents'
import { logger } from '../logger'
import parseUserAgent from 'ua-parser-js'
import { ATTRIBUTION_SURVEY_KEY } from '../../pages/EmailCapturePage/EmailCapturePage.consts'
import { updateAmplitudeUserProperties } from './platforms/amplitude'
import { klaviyo } from './platforms/klaviyo'
import { intercomService } from './platforms/intercom'
import { initFacebook } from './platforms/facebook'
import { capturedUserData } from '../../utils/ApiUtils'

export { _EVENT_NAMES as EVENT_NAMES, _SCREENS as SCREENS }

const LOG_ANALYTICS = false

export const FACEBOOK_INITIATE_CHECKOUT_EVENT_NAME = 'InitiateCheckout'
export const FACEBOOK_PURCHASE_EVENT_NAME = 'Purchase'

class Analytics {
  async init() {
    await _analytics.init()
    initFacebook()
  }

  initPromoCodeAnalytics() {
    _analytics.initPromoCodeAnalytics()
  }

  async identifyUser(userId) {
    await _analytics.identifyUser(userId)
    sentryService.identifyUser(userId)
  }

  setUserEmail({ email, isNewEmail, trackEventName, trackProperties = {} }) {
    _analytics.setUserEmail({
      email,
      isNewEmail,
      trackEventName,
      trackProperties,
    })
  }

  setCountryCode(countryCode) {
    _analytics.setUserCountryCode(countryCode)
  }

  setUserFullName(name) {
    klaviyo.setName(name)
    intercomService.setName(name)
  }

  setLanguage(language) {
    klaviyo.setLanguage(language)
  }

  setPhoneNumber(number) {
    _analytics.setPhoneNumber(number)
  }

  async setUserProperties(userProperties) {
    await _analytics.setUserProperties(userProperties)
  }

  setUserAgentInformation() {
    try {
      const uaInfo = parseUserAgent()
      logger.info('User agent', { uaInfo })
      updateAmplitudeUserProperties({
        set: {
          'UserAgent OS': `${uaInfo.os.name} ${uaInfo.os.version}`,
          'UserAgent Engine': `${uaInfo.engine.name} ${uaInfo.engine.version}`,
          'UserAgent Browser': `${uaInfo.browser.name} ${uaInfo.browser.version}`,
        },
      })
    } catch (e) {
      logger.error('Failed to save user agent information to amplitude', e)
    }
  }

  track(
    eventName,
    eventProperties = {},
    { serverSideAnalyticsPlatforms = [], eventID = uuid() } = {}
  ) {
    // Prevents amplitude warnings for empty attributes
    const cleanEventProperties = filterObjectBlankAttributes(eventProperties)
    _analytics.track(eventName, cleanEventProperties, {
      serverSideAnalyticsPlatforms,
      eventID,
    })

    if (LOG_ANALYTICS && window.KEYS.runtimeEnv !== 'production') {
      logger.info(`[Event] ${eventName}`, { eventProperties })
    }
  }

  trackOnce(eventName, eventProperties) {
    const cleanEventProperties = filterObjectBlankAttributes(eventProperties)
    _analytics.trackOnce(eventName, cleanEventProperties)
  }

  trackOncePerId(eventName, id, eventProperties) {
    const cleanEventProperties = filterObjectBlankAttributes(eventProperties)
    _analytics.trackOncePerId(eventName, id, cleanEventProperties)
  }

  trackScreenView(screenName, eventProperties = {}) {
    _analytics.trackScreenView(screenName, eventProperties)
  }

  facebookPixelTrackOnceInSession(eventName, data = {}) {
    _analytics.facebookPixelTrackOnceInSession(eventName, data)
  }

  facebookTrack(eventName, data = {}, eventID = uuid()) {
    _analytics.facebookTrack(
      eventName,
      {
        ...data,
        attribution_survey_answer:
          StorageManager.get(ATTRIBUTION_SURVEY_KEY) || null,
      },
      eventID
    )
  }

  // This method tracks regular client side analytics but only tracks once per session to the server.
  // This is useful for high-load, frequent actions, like "Photo Picked"
  trackWithOneTimeServerSide(
    eventName,
    eventProperties,
    serverSideAnalyticsPlatforms = []
  ) {
    _analytics.trackWithOneTimeServerSide(
      eventName,
      eventProperties,
      serverSideAnalyticsPlatforms
    )
  }

  trackSmsScreenLastSeen() {
    klaviyo.setSmsScreenLastSeen(getNowUnixTimestamp())
  }

  trackEmailCaptureTermsOfUseTapped() {
    _analytics.trackEmailCaptureTermsOfUseTapped()
  }

  trackEmailCapturePrivacyPolicyTapped() {
    _analytics.trackEmailCapturePrivacyPolicyTapped()
  }

  trackFirstInteraction() {
    _analytics.trackFirstInteraction()
  }

  trackFirstOpen() {
    _analytics.trackFirstOpen()
  }

  trackOncePerSession(event, eventProperties = {}, options = {}) {
    _analytics.trackOncePerSession(event, eventProperties, options)
  }

  capturePhoneNumber(phoneNumber, didConsentToMarketingTexts, source) {
    if (phoneNumber) {
      this.setPhoneNumber(phoneNumber)
      const eventProperties = {
        Source: source,
        Phone: phoneNumber,
        'Is Explicit Consent Given': didConsentToMarketingTexts,
      }
      this.track('Phone Number Captured', eventProperties)

      if (didConsentToMarketingTexts) {
        this.track('Phone Number Consent Given', eventProperties)
      }
    }

    capturedUserData({
      phoneNumber,
      explicitSMSConsentGiven: didConsentToMarketingTexts,
    })
  }
}

export const analytics = new Analytics()
